import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useAutonodeStore = defineStore('waitingAutonode', () => {
  const axios = inject(AXIOS) || baseAxios;

  const autonode = ref({});
  const variableList = ref([]);
  const variableTypeList = ref({
    double: 'double',
    boolean: 'boolean',
    array: 'array',
    identificator: 'identificator',
    date: 'date',
    line: 'line',
    point: 'point',
    polygon: 'polygon'
  });
  const operationList = ref([]);
  const selectedCustomVariableIndex = ref(null);
  const selectedInstructionStep = ref(null);
  const instruction_steps = ref([]);
  const custom_variables = ref([]);
  const output_entities = ref([]);
  const selectedOutputIndex = ref(null);
  const newUuid = ref(null);
  const viewFieldId = ref(null);

  const getVariableListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getOperationListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateAutonodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const generateUuidRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getAutonodeRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });


  function getVariableList(projectId, bpId, nodeId) {
    getVariableListRequest.value = {};
    const req = getVariableListRequest.value;
    req.isLoading = true;
    return axios
      .get(api.waitingAutonodeVariables({ projectId, bpId, nodeId }))
      .then((resp) => {
        variableList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getOperationList(projectId, bpId, nodeId) {
    getOperationListRequest.value = {};
    const req = getOperationListRequest.value;
    req.isLoading = true;
    return axios
      .get(api.waitingAutonodeOperations({ projectId, bpId, nodeId }))
      .then((resp) => {
        operationList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateAutonode(
    projectId,
    bpId,
    nodeId,
    instruction_steps,
    custom_variables,
    output_entities
  ) {
    updateAutonodeRequest.value = {};
    const req = updateAutonodeRequest.value;
    req.isLoading = true;
    let token_view_field_id = viewFieldId.value;
    return axios
      .post(api.waitingAutonode({ projectId, bpId, nodeId }), {
        instruction_steps,
        custom_variables,
        output_entities,
        token_view_field_id
      })
      .then(() => {
        
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getAutonode(
    projectId,
    bpId,
    nodeId
  ) {
    getAutonodeRequest.value = {};
    const req = getAutonodeRequest.value;
    req.isLoading = true;
    return axios
      .get(api.waitingAutonode({ projectId, bpId, nodeId }))
      .then((resp) => {
        autonode.value = resp.data;
        viewFieldId.value = resp.data?.token_view_field_id;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function generateUuid() {
    generateUuidRequest.value = {};
    const req = generateUuidRequest.value;
    req.isLoading = true;
    return axios
      .get(api.uuid())
      .then((resp) => {
        newUuid.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    getOperationList,
    getVariableList,
    updateAutonode,
    generateUuid,
    getAutonode,
    operationList,
    variableList,
    variableTypeList,
    selectedCustomVariableIndex,
    autonode,
    instruction_steps,
    custom_variables,
    output_entities,
    selectedInstructionStep,
    selectedOutputIndex,
    newUuid,
    viewFieldId,
    getOperationListRequest,
    getVariableListRequest,
    generateUuidRequest,
    updateAutonodeRequest,
    getAutonodeRequest,
  };
});
