<script setup lang="js">
import { onMounted, ref, watch, onUnmounted } from 'vue';
import * as d3 from 'd3';
import { storeToRefs } from 'pinia';
import { useNodeStore } from '@/stores/node.store';
import { useRoute, useRouter } from 'vue-router';
import _ from 'lodash';

const route = useRoute();
const router = useRouter();
const emit = defineEmits(['createNodeChild', 'updateNode', 'deleteNodeRef']);

const nodeStore = useNodeStore();
const { nodeList, bpNode, deleteNodeRequest, dataForDeleteNodeRef } = storeToRefs(nodeStore);
const svg = ref(null);
const menuContainer = ref(null);
const activeNodeMenuId = ref(null);

function nodeName(title) {
  let keys = Object.keys(title);
  return title[keys[0]];
}
const getTruncateNodeName = (nodeTitle) => {
  let keys = Object.keys(nodeTitle);
  return _.truncate(nodeTitle[keys[0]], {
    length: 18,
    omission: '...'
  });
};
const stratify = d3
  .stratify()
  .id((d) => d.id)
  .parentId((d) => d.parent_id);

const cluster = d3
  .tree()
  .nodeSize([300, 150])
  .separation(function (a, b) {
    return 1.1;
  });

const prepareStratifyNodeList = () => {
  const stratifyNodeList = [];
  nodeList.value.forEach((node) => {
    if (node.parent_nodes.length) {
      node.parent_nodes.forEach((parent) => {
        stratifyNodeList.push({ ...node, parent_id: parent.id });
      });
    } else {
      stratifyNodeList.push({ ...node, parent_id: null });
    }
  });
  return stratifyNodeList;
};

const drawGraph = () => {
  const stratifyNodeList = prepareStratifyNodeList();
  const stratifyData = stratify(stratifyNodeList);
  const clusterData = cluster(stratifyData);

  svg.value.selectAll('*').remove();

  const containerWidth = svg.value.node().getBoundingClientRect().width;
  const containerHeight = svg.value.node().getBoundingClientRect().height;

  const initialTransform = d3.zoomIdentity.translate(containerWidth / 2, containerHeight / 4);

  const root = svg.value.append('g').attr('transform', initialTransform);

  const nodeHeight = 60;
  const nodeWidth = 300;

  const drawCircle = (obj) => {
    const x = obj.x - nodeWidth / 2;
    const y = obj.y - nodeHeight / 2;

    const g = root.append('g').attr('transform', `translate(${x}, ${y})`);

    let fill, stroke;

    if (obj.data.current?.node?.direction_type == 'one') {
      fill = '#fefefe';
      stroke = '#ED960B';
    } else if (obj.data.current?.node?.direction_type == 'all') {
      fill = '#fefefe';
      stroke = '#6263D5';
    }
    if (obj.data.id == route.params.nodeId) {
      fill = '#FEFEFE';
    }

    g.append('rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('width', nodeWidth)
      .attr('height', nodeHeight)
      .attr('x', 0)
      .attr('y', 0)
      .attr('fill', fill)
      .attr('stroke', stroke)
      .attr('stroke-width', 2);
    const iconG = g.append('g').attr('transform', 'translate(20, 13)');

    if (obj.data.current?.node?.role == 'applicant') {
      iconG
        .append('path')
        .attr(
          'd',
          'M25.3473 25.8986C26.1178 25.738 26.5784 24.9312 26.1946 24.2417C25.3487 22.7244 24.017 21.39 22.3141 20.3738C20.1198 19.0645 17.43 18.354 14.6662 18.354C11.9011 18.354 9.21267 19.0631 7.01839 20.3738C5.31546 21.39 3.98382 22.7244 3.13793 24.2417C2.75407 24.9312 3.2147 25.738 3.98521 25.8986C11.0298 27.3667 18.3013 27.3667 25.3459 25.8986'
        )
        .attr('fill', '#0E1111');

      iconG
        .append('path')
        .attr(
          'd',
          'M14.6663 16.9585C18.5208 16.9585 21.6455 13.8338 21.6455 9.97924C21.6455 6.12471 18.5208 3 14.6663 3C10.8117 3 7.68701 6.12471 7.68701 9.97924C7.68701 13.8338 10.8117 16.9585 14.6663 16.9585Z'
        )
        .attr('fill', '#0E1111');
    } else if (obj.data.current?.node?.role == 'employee') {
      iconG
        .append('path')
        .attr(
          'd',
          'M19.0894 9.13408V7.92564C19.0894 6.31631 17.8373 5 16.3143 5H12.3664C10.8436 5 9.59218 6.31631 9.59218 7.92564V9.13408H10.486V7.92564C10.486 6.80922 11.3361 5.89385 12.3664 5.89385H16.3143C17.3445 5.89385 18.1955 6.80922 18.1955 7.92564V9.13408H19.0894ZM24.1696 9.58101H4.84715C3.81693 9.58101 3 10.5158 3 11.6323V15H11.2123V13.3193C11.2116 13.2613 11.2224 13.2036 11.2442 13.1498C11.2661 13.096 11.2984 13.0471 11.3393 13.0059C11.3803 12.9647 11.429 12.9321 11.4827 12.91C11.5364 12.8879 11.5939 12.8767 11.652 12.8771H16.9979C17.2448 12.8771 17.4693 13.0726 17.4693 13.3193V15H26.0168V11.6323C26.0168 10.5158 25.1998 9.58101 24.1696 9.58101ZM17.4693 17.5322C17.4693 17.779 17.2448 17.9609 16.9979 17.9609H11.652C11.5947 17.9628 11.5376 17.9531 11.4841 17.9324C11.4306 17.9117 11.3818 17.8803 11.3408 17.8403C11.2997 17.8003 11.2671 17.7523 11.2451 17.6994C11.223 17.6465 11.2118 17.5896 11.2123 17.5322V15.8939H4.34078V22.9682C4.34078 24.0846 5.19156 25 6.22173 25H22.7945C23.8247 25 24.676 24.0846 24.676 22.9682V15.8939H17.4693V17.5322Z'
        )
        .attr('fill', '#0E1111');

      iconG
        .append('path')
        .attr('d', 'M16.5752 13.771H12.106V17.0671H16.5752V13.771Z')
        .attr('fill', '#0E1111');
    } else if (obj.data.current?.node?.role == 'autonode') {
      iconG
        .append('path')
        .attr(
          'd',
          'M5.00022 11.2501C5.32522 11.5751 5.42522 12.0376 5.31272 12.4751C4.84937 14.2768 4.89263 16.1714 5.43772 17.9501C6.53772 21.5876 9.73772 24.3251 13.4877 24.8876C20.3877 25.9001 26.2252 19.8126 24.7752 12.8626C23.9627 8.95008 20.7377 5.85008 16.8127 5.16258C15.376 4.90709 13.9012 4.9625 12.4877 5.32508C12.0502 5.43758 11.5752 5.32508 11.2627 5.01258C10.5627 4.31258 10.9127 3.15008 11.8502 2.90008C13.6877 2.42508 15.6752 2.35008 17.7252 2.78758C22.7002 3.86258 26.5877 8.01258 27.3502 13.0376C28.6502 21.5501 21.3252 28.7626 12.8002 27.3126C7.78772 26.4501 3.72522 22.4876 2.75022 17.5001C2.36272 15.5126 2.45022 13.6001 2.90022 11.8376C3.15022 10.9001 4.31272 10.5626 5.00022 11.2501ZM8.75022 6.87508C8.75022 7.91258 7.91272 8.75008 6.87522 8.75008C5.83772 8.75008 5.00022 7.91258 5.00022 6.87508C5.00022 5.83758 5.83772 5.00008 6.87522 5.00008C7.91272 5.00008 8.75022 5.83758 8.75022 6.87508ZM15.0377 11.2376H14.9502L13.5002 15.3751H16.4877L15.0377 11.2376ZM15.0002 22.5001C19.1377 22.5001 22.5002 19.1376 22.5002 15.0001C22.5002 10.8626 19.1377 7.50008 15.0002 7.50008C10.8627 7.50008 7.50022 10.8626 7.50022 15.0001C7.50022 19.1376 10.8627 22.5001 15.0002 22.5001ZM10.7627 18.2876L13.8002 10.2126C13.9877 9.71258 14.4627 9.37508 15.0002 9.37508C15.5377 9.37508 16.0252 9.71258 16.2127 10.2126L19.2502 18.2876C19.4502 18.8126 19.0627 19.3751 18.5002 19.3751C18.1627 19.3751 17.8502 19.1626 17.7377 18.8376L17.0377 16.8251H13.0002L12.2877 18.8501C12.1752 19.1751 11.8752 19.3876 11.5252 19.3876C11.3939 19.3868 11.2647 19.3545 11.1486 19.2933C11.0325 19.232 10.9328 19.1437 10.858 19.0358C10.7832 18.9279 10.7355 18.8036 10.7189 18.6733C10.7023 18.5431 10.7173 18.4108 10.7627 18.2876Z'
        );
    } else if (obj.data.current?.node?.role == 'waiting_autonode') {
      iconG
        .append('path')
        .attr(
          'd',
          'M10.3276 3.71135C9.80959 3.89403 9.53775 4.46207 9.72043 4.98009C9.90311 5.49811 10.4711 5.76995 10.9891 5.58727C12.9731 4.88762 15.1841 4.77407 17.3626 5.39416C22.9538 6.98562 26.1971 12.8099 24.6056 18.4011C24.4128 19.0785 24.1582 19.7206 23.8501 20.3232C23.6 20.8123 23.7938 21.4115 24.2828 21.6616C24.7719 21.9116 25.3711 21.7179 25.6211 21.2288C25.988 20.5113 26.2903 19.7484 26.5188 18.9457C28.4109 12.2979 24.5549 5.37321 17.9072 3.481C15.3196 2.74447 12.6872 2.87923 10.3276 3.71135Z'
        )
        .attr('fill', '#5F5F5F');
      iconG
        .append('path')
        .attr(
          'd',
          'M2.44263 12.0927C2.21415 12.8954 2.06935 13.7031 2.00339 14.5063C1.95843 15.0537 2.36577 15.534 2.91321 15.5789C3.46065 15.6239 3.94089 15.2165 3.98585 14.6691C4.04125 13.9945 4.16297 13.3146 4.35577 12.6372C4.50615 12.1089 4.19978 11.5588 3.67148 11.4084C3.14318 11.258 2.59301 11.5644 2.44263 12.0927Z'
        )
        .attr('fill', '#5F5F5F');

      iconG
        .append('path')
        .attr(
          'd',
          'M4.45983 18.7473C4.29086 18.2246 3.7302 17.9379 3.20755 18.1069C2.6849 18.2758 2.39819 18.8365 2.56715 19.3592C3.07225 20.9215 3.88038 22.3728 4.94255 23.6249C5.29788 24.0438 5.92549 24.0953 6.34437 23.74C6.76324 23.3846 6.81475 22.757 6.45943 22.3381C5.56541 21.2842 4.88496 20.0623 4.45983 18.7473Z'
        )
        .attr('fill', '#5F5F5F');

      iconG
        .append('path')
        .attr(
          'd',
          'M9.67668 24.8887C9.18762 24.6386 8.58844 24.8324 8.33838 25.3214C8.08831 25.8105 8.28205 26.4097 8.77111 26.6598C9.48858 27.0266 10.2515 27.3289 11.0542 27.5574C11.8569 27.7859 12.6647 27.9307 13.4678 27.9966C14.0152 28.0416 14.4954 27.6342 14.5404 27.0868C14.5854 26.5393 14.178 26.0591 13.6306 26.0141C12.956 25.9587 12.2761 25.837 11.5988 25.6442C10.9214 25.4514 10.2794 25.1969 9.67668 24.8887Z'
        )
        .attr('fill', '#5F5F5F');

      iconG
        .append('path')
        .attr(
          'd',
          'M17.7087 25.5401C17.1861 25.7091 16.8994 26.2698 17.0683 26.7924C17.2373 27.3151 17.798 27.6018 18.3206 27.4328C19.883 26.9277 21.3342 26.1196 22.5863 25.0574C23.0052 24.7021 23.0567 24.0745 22.7014 23.6556C22.346 23.2367 21.7184 23.1852 21.2996 23.5405C20.2457 24.4346 19.0237 25.115 17.7087 25.5401Z'
        )
        .attr('fill', '#5F5F5F');

      iconG
        .append('path')
        .attr('d', 'M14.6741 11.5916H14.587L13.1449 15.7067H16.1162L14.6741 11.5916Z')
        .attr('fill', '#5F5F5F');

      iconG
        .append('path')
        .attr(
          'd',
          'M14.6368 22.793C18.7518 22.793 22.096 19.4488 22.096 15.3337C22.096 11.2187 18.7518 7.8744 14.6368 7.8744C10.5217 7.8744 7.17752 11.2187 7.17752 15.3337C7.17752 19.4488 10.5217 22.793 14.6368 22.793ZM10.4223 18.6034L13.4433 10.5722C13.6298 10.0749 14.1022 9.73923 14.6368 9.73923C15.1713 9.73923 15.6562 10.0749 15.8427 10.5722L18.8637 18.6034C19.0626 19.1255 18.6772 19.685 18.1177 19.685C17.7821 19.685 17.4713 19.4736 17.3594 19.1504L16.6632 17.1488H12.6476L11.939 19.1628C11.8271 19.4861 11.5287 19.6974 11.1806 19.6974C11.0501 19.6967 10.9216 19.6645 10.8061 19.6036C10.6906 19.5427 10.5914 19.4549 10.517 19.3476C10.4426 19.2402 10.3952 19.1166 10.3787 18.9871C10.3622 18.8575 10.3772 18.7259 10.4223 18.6034Z'
        )
        .attr('fill', '#5F5F5F');

      iconG
        .append('path')
        .attr(
          'd',
          'M4.5 3C6.43305 3 8 4.56695 8 6.5C8 8.43305 6.43305 10 4.5 10C2.56695 10 1 8.43305 1 6.5C1 4.56695 2.56695 3 4.5 3ZM4.5 4.4C4.40717 4.4 4.31815 4.43687 4.25251 4.50251C4.18687 4.56815 4.15 4.65717 4.15 4.75V6.5C4.15002 6.59282 4.18691 6.68183 4.25255 6.74745L5.30255 7.79745C5.36856 7.86121 5.45697 7.89648 5.54874 7.89569C5.64051 7.89489 5.72829 7.85808 5.79319 7.79319C5.85808 7.72829 5.89489 7.64051 5.89569 7.54874C5.89648 7.45697 5.86121 7.36856 5.79745 7.30255L4.85 6.3551V4.75C4.85 4.65717 4.81313 4.56815 4.74749 4.50251C4.68185 4.43687 4.59283 4.4 4.5 4.4Z'
        )
        .attr('fill', '#5F5F5F');
    }

    g.append('text')
      .attr('x', 60)
      .attr('y', 35)
      .attr('font-size', 18)
      .attr('cursor', 'pointer')
      .text(getTruncateNodeName(obj.data.current.node.title))
      .on('click', () => {
        bpNode.value = obj.data;
        nodeStore.isShowSidebar = true;
        router.push({
          name: 'input-fields',
          params: {
            projectId: route.params.projectId,
            bpId: route.params.bpId,
            nodeId: obj.data.id
          }
        });
      })
      .append('title')
      .text(nodeName(obj.data.current.node.title));

    if (obj.data.id == route.params.nodeId) {
      g.append('text')
        .attr('x', 0)
        .attr('y', -5)
        .attr('font-size', 14)
        .attr('fill', obj.data.current?.node?.direction_type == 'one' ? '#ED960B' : '#6263D5')
        .text('Выбрано');
    }
    g.append('text')
      .attr('x', 265)
      .attr('y', 35)
      .attr('font-size', 24)
      .attr('cursor', 'pointer')
      .attr('fill', '#AEAEAE')
      .text('⋯')
      .on('click', (event) => {
        event.stopPropagation();

        if (menuContainer.value) {
          if (activeNodeMenuId.value == obj.data.current?.node.id) {
            menuContainer.value.remove();
            menuContainer.value = null;
            activeNodeMenuId.value = null;
            return;
          }
          menuContainer.value.remove();
          menuContainer.value = null;
          activeNodeMenuId.value = null;
        }

        activeNodeMenuId.value = obj.data.current?.node.id;
        menuContainer.value = d3
          .select('body')
          .append('div')
          .attr('class', 'node-context-menu')
          .style('position', 'absolute')
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY}px`);

        menuContainer.value
          .append('div')
          .attr('class', 'node-context-menu_item node-context-menu_item_plus-ico')
          .text('Создать дочерний узел')
          .on('click', () => {
            bpNode.value = obj.data;
            emit('createNodeChild');
            menuContainer.value.remove();
            menuContainer.value = null;
          });

        menuContainer.value
          .append('div')
          .attr('class', 'node-context-menu_item node-context-menu_item_info-ico')
          .text('Информация')
          .on('click', () => {
            bpNode.value = obj.data;
            emit('updateNode', true);
            menuContainer.value.remove();
            menuContainer.value = null;
          });

        menuContainer.value
          .append('div')
          .attr('class', 'node-context-menu_item node-context-menu_item_edit-ico')
          .text('Редактировать')
          .on('click', () => {
            bpNode.value = obj.data;
            emit('updateNode', false);
            menuContainer.value.remove();
            menuContainer.value = null;
          });

        menuContainer.value
          .append('div')
          .attr('class', 'node-context-menu_item node-context-menu_item_delete-ico')
          .text('Удалить')
          .on('click', () => {
            bpNode.value = obj.data;
            emit('deleteNode', false);
            menuContainer.value.remove();
            menuContainer.value = null;
          });

        if (obj.data.current?.node?.role == 'autonode') {
          menuContainer.value
            .append('div')
            .attr('class', 'node-context-menu_item node-context-menu_item_info-ico')
            .text('Автоузел')
            .on('click', () => {
              bpNode.value = obj.data;
              router.push({
                name: 'autonode',
                params: {
                  projectId: route.params.projectId,
                  bpId: route.params.bpId,
                  nodeId: obj.data.id
                }
              });
              menuContainer.value.remove();
              menuContainer.value = null;
            });
        }

        if (obj.data.current?.node?.role == 'waiting_autonode') {
          menuContainer.value
            .append('div')
            .attr('class', 'node-context-menu_item node-context-menu_item_info-ico')
            .text('Ожидающий автоузел')
            .on('click', () => {
              bpNode.value = obj.data;
              router.push({
                name: 'waiting-autonode',
                params: {
                  projectId: route.params.projectId,
                  bpId: route.params.bpId,
                  nodeId: obj.data.id
                }
              });
              menuContainer.value.remove();
              menuContainer.value = null;
            });
        }

        d3.select('body').on(
          'click',
          () => {
            if (menuContainer.value) {
              menuContainer.value.remove();
              menuContainer.value = null;
            }
          },
          { once: true }
        );
      });

    if (obj.children) {
      obj.children.forEach((d) => {
        drawCircle(d);
      });
    }
  };

  const drawLines = () => {
    root
      .append('g')
      .selectAll('path')
      .data(clusterData.links())
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', '#555')
      .attr('stroke-opacity', 0.4)
      .attr('stroke-width', 1.5)
      .attr('marker-end', 'url(#arrowhead)')
      .attr('marker-start', 'url(#dot)')
      .attr('d', (d) => {
        const path = d3.path();
        const xSource = d.source.x;
        const ySource = d.source.y + 30;
        const xTarget = d.target.x;
        const yTarget = d.target.y - 30;

        path.moveTo(xSource, ySource);
        path.lineTo(xSource, ySource + 25);
        if (xSource > xTarget) {
          path.arc(xSource - 20, ySource + 25, 20, 0, Math.PI / 2);
          path.lineTo(xTarget + 20, yTarget - 45);
          path.arc(xTarget + 20, yTarget - 25, 20, -1.5, Math.PI, true);
        } else if (xSource < xTarget) {
          path.arc(xSource + 20, ySource + 25, 20, Math.PI, Math.PI / 2, true);
          path.lineTo(xTarget - 20, yTarget - 45);
          path.arc(xTarget - 20, yTarget - 25, 20, Math.PI * 1.5, Math.PI * 2);
        }

        path.lineTo(xTarget, yTarget);
        return path.toString();
      })
      .on('mouseover', function () {
        d3.select(this).attr('stroke', 'red').attr('cursor', 'pointer');
      })
      .on('mouseout', function () {
        d3.select(this).attr('stroke', '#555');
      })
      .on('click', function (event, d) {
        dataForDeleteNodeRef.value.sourceNode = d.source.data;
        dataForDeleteNodeRef.value.targetNode = d.target.data;
        emit('deleteNodeRef');
      });
  };
  drawCircle(clusterData);
  drawLines();
};

watch(
  nodeList,
  () => {
    drawGraph();
  },
  { deep: true }
);

watch(
  () => route.params.nodeId,
  () => {
    drawGraph();
  }
);

onMounted(() => {
  svg.value = d3.select('#nodes');
  const zoom = d3.zoom().on('zoom', (event) => {
    svg.value.select('g').attr('transform', event.transform);
  });
  svg.value.call(zoom);
  const containerWidth = svg.value.node().getBoundingClientRect().width;
  const containerHeight = svg.value.node().getBoundingClientRect().height;
  const initialTransform = d3.zoomIdentity.translate(containerWidth / 2, containerHeight / 4);
  svg.value.call(zoom.transform, initialTransform);
  drawGraph();
});
function isDeleteError() {
  if (!deleteNodeRequest.value.error?.errors) {
    return deleteNodeRequest.value.error;
  }
}
onUnmounted(() => {
  svg.value.on('.zoom', null);
});
</script>

<template>
  <p v-if="isDeleteError()" class="error-mesage px-5">
    {{ isDeleteError() }}
  </p>
  <svg id="nodes" class="chart">
    <defs>
      <marker
        id="arrowhead"
        viewBox="0 0 10 10"
        refX="10"
        refY="5"
        markerWidth="5"
        markerHeight="5"
        orient="auto"
      >
        <path d="M10 5 0 10 0 0Z" fill="#aaa"></path>
      </marker>
      <marker
        id="dot"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="4"
        markerHeight="4"
        orient="auto"
      >
        <circle cx="5" cy="5" r="5" fill="#aaa"></circle>
      </marker>
    </defs>
  </svg>
</template>
