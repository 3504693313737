import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';
import ClassDiagram from '@/assets/js/registriesDiagram/ClassDiagram';

export const useRegistryStore = defineStore('registry', () => {
  const axios = inject(AXIOS) || baseAxios;
  const graph = ref(null);
  const structure = ref({
    classes: [],
    references: []
  });

  const regTables = ref([]);

  const regTableLinks = ref([]);

  const getTableListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createRegTableRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteRegTableRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createRegTableColumnRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateRegTableColumnRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteRegTableColumnRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createTableLinkRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteTableLinkRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const fixateRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getRegTableList(projectId) {
    getTableListRequest.value = {};
    const req = getTableListRequest.value;
    req.isLoading = true;

    return axios
      .get(api.regTables({ projectId }))
      .then((resp) => {
        regTables.value = resp.data.tables;
        regTableLinks.value = resp.data.references;
        structure.value.classes = regTables.value;
        structure.value.references = regTableLinks.value;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function initGraph(el) {
    if (!structure.value) return;

    el.innerHTML = '';

    const newGraph = new ClassDiagram({
      wrapper: el,
      classes: structure.value.classes,
      refs: structure.value.references
    });

    newGraph.draw();
    graph.value = newGraph;
  }

  function createRegTable(projectId, id, description) {
    createRegTableRequest.value = {};
    const req = createRegTableRequest.value;
    req.isLoading = true;

    return axios
      .post(api.regTables({ projectId }), {
        id,
        description
      })
      .then(() => {})
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteRegTable(projectId, regTableId) {
    deleteRegTableRequest.value = {};
    const req = deleteRegTableRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.regTableById({ projectId, regTableId }))
      .then(() => {})
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function fixateRegistries(projectId) {
    fixateRequest.value = {};
    const req = fixateRequest.value;
    req.isLoading = true;

    return axios
      .post(api.regTablesFixate({ projectId }))
      .then(() => {})
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createRegTableColumn(
    projectId,
    regTableId,
    description,
    type,
    is_unique,
    is_fk,
    name
  ) {
    createRegTableColumnRequest.value = {};
    const req = createRegTableColumnRequest.value;
    req.isLoading = true;

    return axios
      .post(api.regTableColumn({ projectId, regTableId }), {
        description,
        type,
        is_unique,
        is_fk,
        name
      })
      .then(() => {})
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function updateRegTableColumn(
    projectId,
    regTableId,
    regTableColumnId,
    description,
    type,
    is_unique,
    is_fk,
    name
  ) {
    updateRegTableColumnRequest.value = {};
    const req = updateRegTableColumnRequest.value;
    req.isLoading = true;

    return axios
      .patch(api.regTableColumnById({ projectId, regTableId, regTableColumnId }), {
        description,
        type,
        is_unique,
        is_fk,
        name
      })
      .then(() => {})
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteRegTableColumn(projectId, regTableId, regTableColumnId) {
    deleteRegTableColumnRequest.value = {};
    const req = deleteRegTableColumnRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.regTableColumnById({ projectId, regTableId, regTableColumnId }))
      .then(() => {})
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createTableLink(
    projectId,
    regTableId,
    regTableColumnId,
    regTable2Id,
    ref_reg_column_id
  ) {
    createTableLinkRequest.value = {};
    const req = createTableLinkRequest.value;
    req.isLoading = true;

    return axios
      .post(api.regTableColumnByIdRefer({ projectId, regTableId, regTableColumnId }), {
        ref_reg_column_id
      })
      .then(() => {})
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteTableLink(projectId, regTableId, regTableRefId) {
    deleteTableLinkRequest.value = {};
    const req = deleteTableLinkRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.regTableRefId({ projectId, regTableId, regTableRefId }))
      .then(() => {})
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    regTables,
    regTableLinks,
    graph,
    structure,
    createRegTableRequest,
    deleteRegTableRequest,
    createRegTableColumnRequest,
    updateRegTableColumnRequest,
    createTableLinkRequest,
    deleteTableLinkRequest,
    getTableListRequest,
    createRegTableRequest,
    deleteRegTableRequest,
    createRegTableColumnRequest,
    updateRegTableColumnRequest,
    createTableLinkRequest,
    deleteTableLinkRequest,
    deleteRegTableColumnRequest,
    fixateRequest,
    getRegTableList,
    createRegTable,
    deleteRegTable,
    createRegTableColumn,
    updateRegTableColumn,
    deleteRegTableColumn,
    createTableLink,
    deleteTableLink,
    initGraph,
    fixateRegistries
  };
});
