<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useBusinessProcessStore } from '@/stores/businessProcess.store';
import { useProjectStore } from '@/stores/project.store';
import { useNodeStore } from '@/stores/node.store';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import NodesScheme from '@/components/NodesScheme.vue';
import NodeCreateModal from '@/components/modals/NodeCreateModal.vue';
import NodeUpdateModal from '@/components/modals/NodeUpdateModal.vue';
import NodeDeleteModal from '@/components/modals/DeleteModal.vue';
import NodeDeleteRefModal from '@/components/modals/NodeDeleteRefModal.vue';

const route = useRoute();
const bpStore = useBusinessProcessStore();
const nodeStore = useNodeStore();
const projectStore = useProjectStore();

onMounted(() => {
  projectStore.getProject(route.params.projectId);
  bpStore.getBP(route.params.bpId);
  nodeStore.getNodeList(route.params.projectId, route.params.bpId);
});

const { bp } = storeToRefs(bpStore);
const { nodeList, bpNode } = storeToRefs(nodeStore);
const { activeModal, openModal } = useModal();

const isLegendOpen = ref(false);
const isInfoModal = ref(false);

function openInfoModal(isInfoModalValue) {
  isInfoModal.value = isInfoModalValue;
  activeModal.value = 'NodeUpdateModal';
}

function openParentNodeModal() {
  isInfoModal.value = false;
  bpNode.value = {};
  activeModal.value = 'NodeCreateModal';
}

function doDeleteNode() {
  activeModal.value = null;
  nodeStore.deleteNode(route.params.projectId, route.params.bpId, bpNode.value.id)
}
</script>
<template>
  <div class="container-fluid bp-page-header">
    <div class="page-header d-flex mt-3 align-items-center">
      <RouterLink
        :to="{ name: 'bp', params: { projectId: route.params.projectId } }"
        class="back-btn"
      />
      <h1>{{ bp.name }}</h1>
    </div>
  </div>
  <div class="bp-page">
    <div class="bp-legend" @click="isLegendOpen = !isLegendOpen" :class="{ open: isLegendOpen }">
      <span class="bp-legend_icon"><img src="@/assets/img/nodes-legend-ico.svg" alt="" /></span>
      <div class="bp-legend_data d-flex flex-wrap" v-if="isLegendOpen">
        <div>
          <p class="bp-legend_data_title">Роли:</p>
          <p><img src="@/assets/img/legend-applicant-ico.svg" alt="" />Заявитель</p>
          <p><img src="@/assets/img/legend-employee-ico.svg" alt="" />Исполнитель</p>
          <p><img src="@/assets/img/legend-autonode-ico.svg" alt="" />Автоузел</p>
        </div>
        <div>
          <p class="bp-legend_data_title">Направление:</p>
          <p><img src="@/assets/img/legend-direction-all-ico.svg" alt="" />Все</p>
          <p><img src="@/assets/img/legend-direction-one-ico.svg" alt="" />Один из</p>
        </div>
      </div>
    </div>
    <div class="bp-nodes">
      <div class="bp-nodes-btn" v-if="nodeList.length == 0">
        <div @click="openParentNodeModal()" class="orange-btn">
          <img src="@/assets/img/plus-ico.svg" alt="" /> Создать узел
        </div>
      </div>
      <div class="bp-nodes-block" v-else>
        <NodesScheme
          @createNodeChild="activeModal = 'NodeCreateModal'"
          @updateNode="openInfoModal"
          @deleteNode="activeModal = 'NodeDeleteModal'"
          @deleteNodeRef="activeModal = 'NodeDeleteRefModal'"
        />
      </div>
    </div>
    <router-view />
    <NodeCreateModal v-if="activeModal === 'NodeCreateModal'" />
    <NodeUpdateModal v-if="activeModal === 'NodeUpdateModal'" :isInfoModal="isInfoModal" />
    <NodeDeleteModal v-if="activeModal === 'NodeDeleteModal'" :itemText="'этот узел'" @confirmDelete="doDeleteNode()" />
    <NodeDeleteRefModal v-if="activeModal === 'NodeDeleteRefModal'" />
  </div>
</template>