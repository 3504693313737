<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteParams } from '@vueuse/router';
import { useProjectStore } from '@/stores/project.store';
import { useAutonodeStore } from '@/stores/waitingAutonode.store';
import { useInputFieldStore } from '@/stores/inputField.store';
import { useInputGeometriesStore } from '@/stores/inputGeometries.store';
import { useViewFieldStore } from '@/stores/viewField.store';
import { useNodeStore } from '@/stores/node.store';
import { storeToRefs } from 'pinia';
import AutonodeVariables from '@/components/WaitingAutonode/Variables.vue';
import AutonodeOperations from '@/components/WaitingAutonode/Operations.vue';
import AutonodeOutputs from '@/components/WaitingAutonode/Outputs.vue';

const autonodeStore = useAutonodeStore();
const nodeStore = useNodeStore();
const { bpNode } = storeToRefs(nodeStore);
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const nodeId = useRouteParams('nodeId');
const { autonode, instruction_steps, custom_variables, output_entities, updateAutonodeRequest, getAutonodeRequest } = storeToRefs(autonodeStore);
const activeTab = ref('variables');
const router = useRouter();
const inputGeometriesStore = useInputGeometriesStore();
const { inputGeometryList } = storeToRefs(inputGeometriesStore);
const inputFieldStore = useInputFieldStore();
const { inputFieldList } = storeToRefs(inputFieldStore);
const viewFieldStore = useViewFieldStore();
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);

onMounted(() => {
    autonodeStore.getOperationList(projectId.value, bpId.value, nodeId.value);
    autonodeStore.getVariableList(projectId.value, bpId.value, nodeId.value);
    autonodeStore.getAutonode(projectId.value, bpId.value, nodeId.value).then(() => {
        prepareAutonode();
    })
    if (!inputFieldList.value.length) {
        inputFieldStore.getInputFieldList(projectId.value, bpId.value, nodeId.value);
    }
    if (!inputGeometryList.value.length) {
        inputGeometriesStore.getInputGeometriesList(projectId.value, bpId.value, nodeId.value);
    }
    if (!Object.keys(project.value).length) {
        projectStore.getProject(projectId.value);
    }
    if (bpNode.value) {
        nodeStore.getNode(projectId.value, bpId.value, nodeId.value);
    }
    viewFieldStore.clearRequestVariables();
    viewFieldStore.getViewFieldList(projectId.value, bpId.value, nodeId.value);
});

function nodeName() {
    if (bpNode.value.current) {
        let title = bpNode.value.current.node.title;
        let keys = Object.keys(title);
        return title[keys[0]] || null;
    }
    return null;
}

function prepareAutonode() {
    if (autonode.value && !Array.isArray(autonode.value)) {
        let parentMap = {};
        autonode.value.instruction_steps.forEach(step => {
            step.children.forEach(childId => {
                parentMap[childId] = step.id;
            });
        });
        instruction_steps.value = autonode.value.instruction_steps.map(step => {
            return {
                ...step,
                parent_id: parentMap[step.id] || null
            };
        });
        custom_variables.value = autonode.value.custom_variables;
        output_entities.value = autonode.value.output_entities;
    }
}

function updateAutonode() {
    autonodeStore.updateAutonode(projectId.value, bpId.value, nodeId.value, instruction_steps.value, custom_variables.value, output_entities.value).then(() =>
        router.push(
            {
                name: 'input-fields',
                params: {
                    projectId: projectId.value,
                    bpId: bpId.value,
                    nodeId: nodeId.value
                }
            }
        )
    )
}
function isErrorField() {
    if (updateAutonodeRequest.value.error?.errors) {
        return updateAutonodeRequest.value.error.errors || false;
    }
}
function isNotFieldError() {
    if (!(updateAutonodeRequest.value.error?.errors)) {
        return updateAutonodeRequest.value.error;
    }
}

</script>
<template>
    <div class="container-fluid">
        <div class="page-header d-flex mt-3 align-items-center">
            <RouterLink :to="{
                name: 'input-fields', params: {
                    projectId: projectId,
                    bpId: bpId,
                    nodeId: nodeId
                }
            }" class="back-btn" />
            <h1 class="mb-0" v-if="nodeName()"> Автоузел узла "{{ nodeName() }}"</h1>
        </div>
    </div>
    <div class="autoform-page d-flex">
        <div class="autoform_sidebar">
            <div class="bp-section-content_link" :class="{ 'router-link-active': activeTab == 'variables' }"
                @click="activeTab = 'variables'">
                1. Переменные
            </div>
            <div class="bp-section-content_link" :class="{ 'router-link-active': activeTab == 'operations' }"
                @click="activeTab = 'operations'">
                2. Операции
            </div>
            <div class="bp-section-content_link" :class="{ 'router-link-active': activeTab == 'outputs' }"
                @click="activeTab = 'outputs'">
                3. Выходные данные
            </div>
            <div class="px-3 mt-3">
                <div class="orange-btn w-100" @click="updateAutonode()" :disabled="updateAutonodeRequest.isLoading">
                    Сохранить
                </div>
            </div>
            <p v-if="isErrorField()" class="error-mesage">
                <span v-for="error in isErrorField()">{{ error }}</span>
            </p>
            <p v-if="isNotFieldError()" class="error-mesage">
                {{ isNotFieldError() }}
            </p>
        </div>
        <div class="autoform_content" :class="{ 'gray-bg': activeTab == 'operations' }" v-if="autonode">
            <AutonodeVariables v-if="activeTab == 'variables'" />
            <AutonodeOperations v-if="activeTab == 'operations'" />
            <AutonodeOutputs v-if="activeTab == 'outputs'" />
        </div>
    </div>
</template>